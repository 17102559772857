<template>
  <div  class="main-container">  
    
    <img :src="require('../assets/bg1.jpg')" class="bg">
    <div class="wrapper"> 
      <div class="logo">
        <img :src="require('../assets/logo.png')" >
      </div>

      <h4 class="text-center text-white">{{ login.state == 'login' ? 'LOGIN' : 'FORGOT PIN' }}</h4>
      
      <form v-on:submit.prevent="onLogin" v-if="login.state == 'login'">
        
        <div class="alert alert-danger" v-if="errors.login.show">
              {{ errors.login.msg }}
          </div>
          
         <div class="btn-group email-or-mobile">
            <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">Mobile Number</button>
            <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button> 
          </div>

        <div class="form-group" v-if="selected =='email'">
            <label for="formGroupExampleInput"> Email Address:</label>
            <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
            <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
        </div>

         <div class="form-group mobile-container" v-if="selected =='mobile'">
          <label for="loginId">Mobile Number:</label> 
          <div class="input-group mb-3">
          <div class="input-group-prepend">
            <button class="btn text-white" type="button" v-on:click="toggleCountryList">
              <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
              <span>+{{ mobileCode }}</span>
            </button>
          </div>
          

          <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
        </div>

        <div class="country-list" v-if="showCountry">
            <div class="row">
              <div class="col-sm-6" v-for="l in list.mobileCodes" v-on:click="selectCountry(l)">
                <div >
                  <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                </div>

                <div class="country-name"> <span> {{ l.country }} </span> </div>
                
              </div>
              
            </div>
          </div>
           <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
      </div>



        <div class="form-group">
            <label for="exampleFormControlInput1">6-Digit PIN:</label>
            <!-- <input type="password" class="form-control" id="exampleFormControlInput1" placeholder="" v-model="login.loginPass"> -->
            <div class="input-group mb-1">
              <div class="input-group-append">
                <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword">{{ hideOrShow }}</button>
              </div>
              <input type="password" class="form-control" id="inputPass" @keypress="isNumber($event)" maxlength="6" v-model="login.loginPass">
            </div>
            <p class="help-block" v-if="errors.login.loginPass && errors.login.loginPass !== ''"> {{ errors.login.loginPass }} </p>

            <div>
              <a href="#" v-on:click="toggleLogin('forgot-pass')" class="forgot">Forgot Pin?</a>
            </div>
        </div>
        
        <div id="captcha" class="d-flex justify-content-center mx-auto">
                 <div>
                    <img :src="`data:image/png;base64,${captchaString}`"  id="captchaImg"/>
                </div>
                <div>
                    <input type="text" class="form-control text-center" v-model="captchaSum"  @keypress="isNumber($event)">
                </div>
            </div>



        
        
        <div class="form-group ">
            <button type="submit" class="btn btn-main" id="loginBtn">LOGIN</button>
        </div>

        <div class="form-group ">
            <button type="button" class="btn " v-on:click="gotoSignup" id="signupBtn">SIGN UP</button>
        </div>
        <div class="footer">
          <span>&copy; CEZA</span>
        </div>
      </form>



      <form v-on:submit.prevent="onForgotPassword" v-if="login.state == 'forgot-pass'">

        <div class="alert alert-danger" v-if="errors.login.show">
              {{ errors.login.msg }}
          </div>
             
         <div class="btn-group email-or-mobile">
            <button type="button" class="btn " v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')">{{ $t('login.mobileLabel') }}</button>
            <button type="button" class="btn " v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">{{ $t('login.emailAddressLabel') }}</button> 
          </div>

        <div class="form-group" v-if="selected =='email'">
            <label for="formGroupExampleInput">{{ $t('login.emailAddressLabel') }}:</label>
            <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
            <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
        </div>

         <div class="form-group mobile-container" v-if="selected =='mobile'">
          <label for="loginId">{{ $t('login.mobileLabel') }}:</label> 
          <div class="input-group mb-3">
          <div class="input-group-prepend">
            <button class="btn text-white" type="button" v-on:click="toggleCountryList">
              <img class="default-flag" :src="require('../assets/signup_flags/' + country + '.png' )">
              <span>+{{ mobileCode }}</span>
            </button>
          </div>
          

          <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
        </div>

        <div class="country-list" v-if="showCountry">
            <div class="row">
              <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)">
                <div >
                  <img class="flag-list-img" :src="require('../assets/signup_flags/' + l.countryCode + '.png' )">
                </div>

                <div class="country-name"> <span> {{ l.country }} </span> </div>
                
              </div>
              
            </div>
          </div>
           <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
      </div>

            <div class="form-group submit-button text-center pt-3">
                <button class="btn btn-main" id="forgotBtn">{{ $t('login.submit') }}</button>
            </div>

            <a href="#" v-on:click="toggleLogin('login')" class="forgot">{{ $t('login.backToLogin') }}</a>

        </form>


    </div>
    
    
  </div>
</template>

<script>

  import qs from 'querystring'

  export default {
    name: 'Login',
    components: {},
    data: function() {
      return  {
        selected: 'mobile',
        country: 'US',
        mobileCode: '1',
        showCountry: false,
        login: {
          state: 'login'
        },
        errors: {
          login: {
            msg: "",
            show: false,
            loginId: "",
            loginPass: ""
          }
        },
        list: {
          mobileCodes: []
        },
        hideOrShow: "Show",
        captchaString: "",
        captchaSum: "",
        captchaImgSrc: "",
        ifResent: false,
        timerInSeconds: 60,
        isDisabled: false,
        captchaTimer: 10,
      }
    },
    methods: {

      timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                this.isDisabled = true
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.isDisabled = false
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

    captcha() {
            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/login/captcha?fontColor=FFFFFF',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.captchaString = response.data

                })
                .catch((error) => {
                    // Error code goes here
                    this.errorMsg = error.response.data.msgText
                });
        },

      onForgotPassword: function() {
     if(localStorage.getItem("lang") == 'kr'){
          this.errors.login.show = false;
          let loginId = ""; 

          if(this.selected == 'mobile') {
            loginId = this.mobileCode + this.login.loginId
          } else {
            loginId = this.login.loginId
          }
        const params = {
          siteHost: "wallet.ceza.org",
          loginId: loginId, 
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
  

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data,
          url: '/cx/processforgotpin',
        };

        this.axios(options)
          .then(() => { 
            this.errors.login.show = true;
                this.errors.login.msg = "임시 핀이 전송되었습니다";
                
                this.login.loginId = "";
            }).catch((err) => {
                
                this.errors.login.show = true;
                  if (err.response.data) { 

                    if(err.response.data.fieldErrors) {

                      err.response.data.fieldErrors.forEach((error) => {

                        switch (error.field) {


                          default:
                            this.errors.login[error.field] = error.defaultMessage;
                          break;
                        }
                      });

                    }  else {

                      let translatedError = ""
                      if(err.response.data.msgText == 'Account not found') {
                        translatedError = "계정을 찾을 수 없음"
                      } else if(err.response.data.msgText == 'Please enter email address or mobile phone number') {
                        translatedError = "이메일 주소 또는 휴대폰 번호를 입력하십시오"
                      }
                      this.errors.login.msg = translatedError
                    }
                  }
            })
          } else {
     this.errors.login.show = false;
     let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }
      const params = {
        siteHost: "wallet.ceza.org",
        loginId: loginId, 
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
 

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data,
        url: '/cx/processforgotpin',
      };

      this.axios(options)
        .then(() => { 
           this.errors.login.show = true;
              this.errors.login.msg = "Temporary PIN has been sent";
              
              this.login.loginId = "";
          }).catch((err) => {
               
               this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
          })
        } 
    },

    resendCode: function() {
     this.errors.login.show = false;
     let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }
      const params = {
        siteHost: "wallet.ceza.org",
        loginId: loginId, 
      }

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
 

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data,
        url: '/cx/processforgotpin',
      };

      this.axios(options)
        .then(() => { 
           this.errors.login.show = true;
              this.errors.login.msg = "Temporary PIN has been sent";
              this.isResent = true
              this.timerInSeconds = 60
              this.timer();
              this.login.loginId = "";
          }).catch((err) => {
               
               this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
          })

    },

       toggleLogin: function(state) {
        this.errors.login.show = false;
        this.errors.login.msg = "";
        this.login.state = state;
      },
      gotoSignup: function(){
        window.location.href = "/#/signup";
      },
      toggleCountryList: function(){
        this.showCountry = !this.showCountry;
      },
      toggleShowPassword: function(){
        this.login.showPassword = !this.login.showPassword;

        if(this.login.showPassword) {
          document.getElementById('inputPass').type = 'text';
          this.hideOrShow = "Hide"
        } else {
          document.getElementById('inputPass').type = 'password';
          this.hideOrShow = "Show"
        }
      },
      toggleLoginOption(selected) {
        this.selected = selected;
        this.login.loginId = "";

        this.errors.login.loginId = '';
        this.errors.login.loginPass = '';

        if(selected == 'email') {
          this.login.loginId = "";
        }
      },
      getMobileCodes: function() {
        let mobilecodes = '';

        if(localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == null || localStorage.getItem("lang") == '' || localStorage.getItem("lang") == undefined) {
          mobilecodes = '/assets/mobilecodes.json'
        } else if(localStorage.getItem("lang") == 'kr') {
          mobilecodes = '/assets/kr-mobilecodes.json'
        }

        this.axios.get(mobilecodes)
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);

          })
      },
      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode; 
      },
      validateEmail: function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      onLogin: function() {

        if(localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == null || localStorage.getItem("lang") == '' || localStorage.getItem("lang") == undefined) {
        let hasError = false;

        if(this.selected == 'mobile') {
            if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



              this.errors.login.loginId = "Please provide a valid  Mobile no.";
              hasError = true;
            } 

            if(this.login.loginId.length < 10) {

              this.errors.login.loginId = "Please provide a valid  Mobile no.";
              hasError = true;
            } 

            if(this.login.loginId.substring(0,1) === '0') {
              this.errors.login.loginId = "Please remove 0 in front of your mobile number";
              hasError = true;
            }
        } 

         if(this.selected == 'email') {
          if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



              this.errors.login.loginId = "Please provide a valid Email Address.";
              hasError = true;
            } 

            if(!this.validateEmail(this.login.loginId)) {
              
              this.errors.login.loginId = "Please provide a valid Email Address.";
              hasError = true;
            }
         }
        

        if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
          this.errors.login.loginPass = "Please provide PIN.";
          hasError = true;
        }  else {
           if(this.login.loginPass.length < 6) {
            this.errors.login.loginPass = "PIN must be 6-digits.";
          hasError = true;
          }
        }

        

        if(hasError) {
          return;
        }

        this.errors.login.show = false;

        let loginId = ""; 

        if(this.selected == 'mobile') {
          loginId = this.mobileCode + this.login.loginId
        } else {
          loginId = this.login.loginId
        }
        
        const params = {
          loginHost: "wallet.ceza.org",
          loginId: loginId,
          pin: this.login.loginPass,
          captcha: this.captchaSum
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
   

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data,
          url: '/cx/login/pin',
        };

        this.axios(options)
          .then((response) => {
              //console.log(response.data.tokenId)
              localStorage.setItem("tokenId",response.data.tokenId); 
              window.location.href = "/";
            }).catch((err) => {
                this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                 
          })
        } else if(localStorage.getItem("lang") == 'kr') {
          let hasError = false;

          if(this.selected == 'mobile') {
              if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



                this.errors.login.loginId = "올바른 휴대폰 번호를 입력하십시오.";
                hasError = true;
              } 

              if(this.login.loginId.length < 10) {

                this.errors.login.loginId = "올바른 휴대폰 번호를 입력하십시오.";
                hasError = true;
              } 

              if(this.login.loginId.substring(0,1) === '0') {
                this.errors.login.loginId = "휴대폰 번호 앞에 0을 삭제해 주세요.";
                hasError = true;
              }
          } 

          if(this.selected == 'email') {
            if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



                this.errors.login.loginId = "올바른 이메일 주소를 제공하십시오.";
                hasError = true;
              } 

              if(!this.validateEmail(this.login.loginId)) {
                
                this.errors.login.loginId = "올바른 이메일 주소를 제공하십시오.";
                hasError = true;
              }
          }
          

          if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
            this.errors.login.loginPass = "핀을 제공해 주세요.";
            hasError = true;
          }  else {
            if(this.login.loginPass.length < 6) {
              this.errors.login.loginPass = "핀은 6자리여야 합니다.";
            hasError = true;
            }
          }

          

          if(hasError) {
            return;
          }

          this.errors.login.show = false;

          let loginId = ""; 

          if(this.selected == 'mobile') {
            loginId = this.mobileCode + this.login.loginId
          } else {
            loginId = this.login.loginId
          }
          
          const params = {
            loginHost: "wallet.ceza.org",
            loginId: loginId,
            pin: this.login.loginPass,
            captcha: this.captchaSum
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data,
            url: '/cx/login/pin',
          };

          this.axios(options)
            .then((response) => {
                //console.log(response.data.tokenId)
                localStorage.setItem("tokenId",response.data.tokenId); 
                window.location.href = "/";
              }).catch((err) => {
                  this.errors.login.show = true;
                  if (err.response.data) { 

                    if(err.response.data.fieldErrors) {

                      err.response.data.fieldErrors.forEach((error) => {

                        switch (error.field) {


                          default:
                            this.errors.login[error.field] = error.defaultMessage;
                          break;
                        }
                      });

                    }  else {
                      let translatedError = ""
                      if(err.response.data.msgText == 'Account not found') {
                        translatedError = "계정을 찾을 수 없음"
                      } else if(err.response.data.msgText == 'The Email and PIN do not match. Please re-enter your login information.') {
                        translatedError = "전자 메일과 핀이 일치하지 않습니다. 로그인 정보를 다시 입력하십시오."
                      } else if(err.response.data.msgText == 'The Email and PIN do not match. Your account will be locked on the next failed attempt.') {
                        translatedError = "전자 메일과 핀이 일치하지 않습니다. 다음 번 시도 실패 시 계정이 잠깁니다."
                      } else if(err.response.data.msgText == "Account is locked out. Please contact customer support or click on 'Forgot your password' link below.") {
                        translatedError = "계정이 잠겼습니다. 고객 지원부에 문의하거나 아래의 '비밀번호를 잊어버렸습니다' 링크를 클릭하십시오."
                      } else if(err.response.data.msgText == "Captcha is required") {
                        translatedError = "캡차는 필수 항목입니다."
                      }else if(err.response.data.msgText == "Invalid Captcha") {
                        translatedError = "잘못된 캡차."
                      }
                      this.errors.login.msg = translatedError
                    }
                  }
                  
            })
          }
      },

      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
    },

    beforeMount(){
      this.getMobileCodes();
      this.captcha();
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">

  .otp-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1em;
      div {
      margin: 0 .25em;

      .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: none;
              box-shadow: none;
              outline: none; 
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
  }

  .main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #signupBtn {
    border: 1px solid #0567d7;
    color: #fff;
    width: 100%;
  }
  .forgot {
        display: flex;
    justify-content: end;
  }
   a {
    color: #fff;
    text-decoration: none;
  }
  .mobile-container {
      position: relative;
      .default-flag {
        width: 33px;
         margin-right:0.25em;
      }

      .country-list {
          position: absolute;
          background: #ccc;
          color: #333;
          top: 70px;
          padding: 0.5em;
          height: 13em;
          overflow: auto;
              z-index: 3;
          .row {
            margin: 0 !important;
            .col-sm-6 {
                display: flex;
              padding: 0.5em;
              align-items: center;
              cursor: pointer;
              &:hover {
                background: darken(#ccc, 10%);
              }
              .country-name {
                margin-left: 0.5em;
              }

              span {
                font-size: 0.9em;
              }
            }
          }
      }
    }

    .flag-list-img {
      width: 33px;
    }
  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #0567d7;
          border-bottom: 1px solid #0567d7;
        }
      }
 
    }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    img{
      width: 130px;  
    }
    
  }

  .email-or-mobile {
    button {
      color: #FFF;  
    }
    
  }

  .wrapper {
    background: linear-gradient(to right, #020141, #0f0611);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    width: 25em;
    margin-top: 1em;
    border-radius:10px;
    form {
      color: #FFF;
    }
  }

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {

    .wrapper {
      padding: 1em !important;
      h4 {
        font-size: 1.25em;
      }

      .email-or-mobile { 

        .btn {
          font-size: 1em;
        }
      }
    }

    button, input {
      font-size: 85%;
    }

    .mobile-container {
      .default-flag {
        width: 25px;
      }
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {

    .wrapper {
      padding: 2em;
     
    }

     #app {
      
      padding: 2em;
      font-size: 85%;

    }
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(max-width: 768px) {
    .wrapper {
      width: 30em;
    }

    #app {
      
      padding: 4em; 

    }
  }

</style>



